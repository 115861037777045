@import "styles/abstracts/index";
.container {
  position: fixed;
  bottom: 1rem;
  z-index: $zCookie;
  width: 100%;
  display: flex;
  justify-content: center;
}

.banner {
  border-radius: var(--border-radius-sm);
  border: 1px solid var(--grey-light);
  box-shadow: 0px 4px 26px rgba(112, 144, 176, 0.17);
  background-color: var(--white);
  padding: 12px 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 0.8rem;

  @include md-down() {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 1rem;
  }
}

.big {
  font-size: 1.3rem;
}

.line {
  height: 45px;
  width: 1px;
  background-color: var(--grey-light);
  display: block;
  margin: 0 12px;

  @include md-down() {
    display: none;
  }
}

.decline {
  color: var(--black-light);
  font-weight: normal;
  cursor: pointer;
}
